import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContentContext } from "../../pages/context";
import PopupMenu from "./PopupMenu";
import Profile from "./Profile";
import useSession from "../../services/useSession";
import defineConfig from "../../config/defineConfig";
import useStorage from "../../hooks/useStorage";
import usersService from "../../services/users";

function Header() {
  const navigate = useNavigate();
  const { removeStorageItem } = useStorage();
  const { getSession, setSessionSetting } = useSession();

  const {
    state: { contentType, userInfo },
    handleUserInfo,
    handleContentType,
  } = useContext(ContentContext);

  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isView, setIsView] = useState(false);
  const [textColor, setTextColor] = useState("text-[#FFFFFF]");

  const handleMenu = () => {
    setIsShowMenu((prev) => !prev);
  };

  const closeMenu = () => {
    setIsShowMenu(false);
  };

  const onClickLogin = () => {
    if (defineConfig.isServer) {
      removeStorageItem("path");
      navigate(defineConfig.Route.LOGIN);
    } else {
      handleUserInfo({
        isLogin: true,
        name: defineConfig.user.test.name,
        email: defineConfig.user.test.email,
        workspaceId: "1234",
        imgSrc: "",
      });
      setIsView(true);
    }
  };

  useLayoutEffect(() => {
    switch (contentType) {
      case "playground":
        setTextColor("text-[#3B3F4E]");
        break;
      case "kms":
        setTextColor("text-[#3B3F4E]");
        break;
      case "builder":
        setTextColor("text-[#3B3F4E]");
        break;
      default:
        setTextColor("text-[#FFFFFF]");
        break;
    }
  }, [contentType]);

  useLayoutEffect(() => {
    // 데모 수정
    if (userInfo.name === "" && !defineConfig.isDemo) {
      getSession()
        .then((response) => {
          console.log("getSession response >>>> ", response);
          if (response.code === 200) {
            handleUserInfo({
              isLogin: true,
              name: response.result.currentData.setting.name,
              email: response.result.currentData.setting.email,
              workspaceId: response.result.currentData.setting.workspace,
            });
            setIsView(true);
          } else {
            setIsView(true);
          }
        })
        .catch((error) => {
          console.error("getSession error >>>> ", error);
          // navigate("/", { replace: true });
          setIsView(true);
        });
    }
  }, [userInfo]);

  // 데모 수정
  useEffect(() => {
    usersService
      .login({
        emailId: defineConfig.user.theventi.email,
        password: defineConfig.user.theventi.password,
      })
      .then((data) => {
        const { code, result } = data;
        if (code === 200) {
          console.log("header login success", result);
          setSessionSetting({
            workspace: result.personalWorkspaceId,
            knowledge: result.knowledgeId,
          }).then((sessionRes) => {
            if (sessionRes.code === 200) {
              console.log("header setSessionSetting success", sessionRes);
              handleUserInfo({
                isLogin: true,
                name: defineConfig.user.theventi.name,
                email: defineConfig.user.theventi.email,
                workspaceId: result.knowledgeId,
              });
              handleContentType(
                defineConfig.ContentRoute.playground.replace("/", ""),
              );
              setIsView(true);
            } else {
              alert("header setSessionSetting failed");
            }
          });
        } else {
          console.log("header login failed");
        }
      });
  }, []);

  return (
    isView && (
      <div className="flex w-full h-[40px] items-center justify-between">
        <div className="flex text-[18px] leading-[20px] font-[600]">
          {contentType === "kms" && defineConfig.ContentList.kms.title}
        </div>
        <div className="flex items-center justify-end space-x-[20px]">
          <div>
            <button
              type="button"
              className={`text-[18px] leading-[24px] font-[500] ${textColor}`}
              onClick={handleMenu}
            >
              Menu
            </button>
            {isShowMenu && <PopupMenu closeMenu={closeMenu} />}
          </div>
          <div className={`${textColor}`}>|</div>
          {userInfo.isLogin ? (
            <Profile userInfo={userInfo} textColor={textColor} />
          ) : (
            <button
              type="button"
              onClick={onClickLogin}
              className={`w-[130px] h-[40px] rounded-[32px] bg-[#0085FF] text-[18px] leading-[21px] font-[600] ${textColor}`}
            >
              LOGIN
            </button>
          )}
        </div>
      </div>
    )
  );
}

export default Header;
