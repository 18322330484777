const ContentInitialState = {
  headerType: "hidden", // flex = show & work, hidden = not show & work, none = not show & not work
  contentType: "",
  bgScreen: "bg-main-screen",
  userInfo: {
    isLogin: false,
    name: "",
    email: "",
    workspaceId: "",
    imgSrc: "https://via.placeholder.com/150",
  },
};

export default ContentInitialState;
