const isServer = process.env.NODE_ENV === "production";

const HOST = isServer
  ? process.env.REACT_APP_HOST
  : process.env.REACT_APP_DEV_HOST;

const defineConfig = {
  isServer: isServer,
  isHost: process.env.REACT_APP_HOST,
  isDevHost: process.env.REACT_APP_DEV_HOST,
  HOST: HOST,
  SERVER: isServer ? "" : "http://127.0.0.1:3010",
  AUTH_SERVER: isServer ? "" : "http://127.0.0.1:22001",
  USER_SERVER: isServer ? "" : "http://127.0.0.1:22200",
  ACCESS_LIST: isServer ? [] : ["http://192.168.1.200:8098"],
  Route: {
    LOGIN: "/login",
  },
  ContentList: {
    search: {
      // text: "안녕하세요 Langsa입니다! Timbel의 새로운 AI 기반 검색 모드입니다 ✨",
      text: "안녕하세요 Langsa입니다! 새로운 AI 기반 검색 모드입니다 ✨",
      url: "",
      title: "Home",
    },
    playground: {
      text: "안녕하세요 Langsa입니다! 새로운 AI 기반 검색 모드입니다 ✨",
      url: isServer ? `${HOST}/playground` : `${HOST}:8099/playground`,
      title: "Playground",
    },
    kms: {
      text: "안녕하세요 Langsa입니다! 새로운 AI 기반 검색 모드입니다 ✨",
      url: isServer ? `${HOST}/kms/knowledge` : `${HOST}:8097/kms/knowledge`,
      title: "AI KMS",
    },
    builder: {
      text: "안녕하세요 Langsa입니다! 새로운 AI 기반 검색 모드입니다 ✨",
      url: isServer ? `${HOST}/builder` : `${HOST}:8097`,
      title: "AI Builder",
    },
  },
  ContentRoute: {
    search: "/",
    playground: `/playground`,
    kms: `/kms/knowledge`,
    builder: `/builder`,
  },

  isDemo: true,
  user: {
    test: {
      email: "timbel@timbel.net",
      password: "timbel123#",
      name: "test user",
    },
    dasan: {
      email: "dasan120@timbel.net",
      password: "dasan123#",
      name: "다산120",
    },
    theventi: {
      email: "theventi@timbel.net",
      password: "theventi123#",
      name: "더벤티",
    },
  },

  SET_LOADING_TIMEOUT: 200,
};
export default defineConfig;
